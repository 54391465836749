<!-- Template -->
<template lang="pug">

	//- ####################################################
	//- Footer
	//- ####################################################
	footer
		
		.accueil.
			Vente directe à la ferme sur RDV, toute l'année, pour les particuliers

		.contact
			div Contactez nous au 04.66.25.65.70 / 06.27.09.56.82 ou contact@lacaracole.fr
			div La Caracole, 181 lieu dit La Cantonade, 30960 Saint-Florent-sur-Auzonnet

</template>


<!-- Script -->
<script>
	export default {
		name: "FooterComponent",
		props: ['someData']
	}
</script>

<style scoped>

</style>