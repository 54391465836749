<template lang="pug">
	.content
		iframe(id="boutique" style="width: 100%;" frameborder="0" ref="iframeContent" :src="url" @load="init" v-resize="{ log: false }")
			| Chargement en cours... Si la page ne se charge pas correctement, vous pouvez
			a(href="https://www.cara-bel.com" target="_blank") accéder directement à la boutique.
</template>

<script>
    export default {
		name: "BoutiqueContentComponent",
		props: {
            url: {
		        type: String,
		        default: "https://www.cara-bel.com"
		    },
        },
        mounted() {
            // this.init();
        },
        methods: {
            init() {
                this.frame = this.$refs.iframeContent.contentWindow;
                const style =
                    '#header .navbar, #footer, .kl-footer-seo, .navbar{ display: none!important; }' //+
	                // '.wsmenucontainer .kl-hero-listing .kl-breadcrumb { background-color: #b0c592!important; }'
                this.frame.postMessage(style, '*');
                window.scrollTo(0,0);
            },
        }
	}



</script>

<style scoped>

</style>