<template lang="pug">
	.content.page_accueil
		
		//- Intro
		//- =============================
		.section_intro

			//- Colonne 1
			.col_1
				
				h1 Qui sommes-nous

				.intro
					p.bold.
						Installés dans les Cévennes, depuis plus de 20 ans, nous avons développé notre élevage et notre ferme de production  d’escargots Petits gris et Gros gris.
					p.
						Notre gamme s’enrichit régulièrement des recettes recueillies auprès des anciens, fiers de nous transmettre leurs savoir-faire avant qu’ils ne se perdent.
					p.
						Nous aimons également créer des recettes plus modernes, répondant à la demande du consommateur qui aime allier tradition, plaisir et coté pratique…

				galerie-component

			//- Colonne 2
			.col_2
				.photo
					img(src="../../assets/images/escargot_sur_tige.jpg" alt="")

				.quote
					| Découvrez une ferme pas comme les autres…
					br
					| et prenez le temps de vivre au rythme des escargots !
		


		//- Section Bloc d'icones
		//- =============================
		.section_icones
			teaser-icon-component
	

		//- Avis 1ere version
		//- =============================	
		//- actu-and-feedback-component


		
		//- Facebook timeline + avis
		//- =============================	
		.section_fb_avis

			h2 Les news et avis des visiteurs

			.grid

				.tous_les_avis

					.avis.tripadvisor
						a(href="https://www.tripadvisor.fr/Attraction_Review-g4760517-d4730019-Reviews-La_Caracole-Saint_Florent_sur_Auzonnet_Gard_Occitanie.html" target="_blank")
							img.avatar(src="~/assets/images/tripadvisor_avatar.svg")
							br
							img.avatar(src="~/assets/images/tripadvisor_rate.png")
						.texte.
							Nº 1 sur 1 choses à voir/à faire à Saint-Florent-sur-Auzonnet - 4,5/5 sur 11 avis
				
					.avis
						img.avatar(src="~/assets/images/user_avatar.svg")
						.texte.
							On a très bien mangé. Un accueil super. Une visite après le repas sur l élevage des escargots. j ai adoré cette journée. 👍👍
						.nom Karine B.

					//- .avis
						img.avatar(src="~/assets/images/user_avatar.svg")
						.texte.
							Nous y sommes allés le 5 mai et avons passés une très agréable journée. Avons appris beaucoup de choses sur l'élevage des escargots, avons goûter des specialités originales. Le repas fut un délice, moi qui suis pas très escargots, je me suis régalée et en ai même repris ! Je reviendrais en famille. Et l'accueil très sympathique.
						.nom Barbara H.

					.avis
						img.avatar(src="~/assets/images/user_avatar.svg")
						.texte.
							Super accueil , lieu chaleureux, j'ai adoré vos spécialités notamment les escargots confits à la graisse de canard et votre omelette aux escargots... un régal !! Je reviendrai avec la famille à bientôt!
						.nom SoNnY Cw



				.fb_comp
					social-component(v-bind:enabledWidgets="['fbtimeline']")



		//- Bandeau
		//- =============================	
		bandeau-info-component(icon="basket" v-bind:lines="['Nos produits sont directement proposés à la ferme, tant aux particuliers qu&apos;aux professionnels.', 'Vente directe à la ferme tous les après-midi entre 15h et 19h, sinon le matin sur rdv.']")




		//- Visites et decouvertes
		//- =============================
		.section_visites_decouverte

			h2 Visites et decouverte

			.textes

				p.bold

					//- a(href="https://www.tripadvisor.fr/Attraction_Review-g4760517-d4730019-Reviews-La_Caracole-Saint_Florent_sur_Auzonnet_Gard_Occitanie.html" target="_blank")
						img.trip_advisor(src="../../assets/images/trip_advisor.jpg")
					
					| Notre élevage, en conditions naturelles, reste saisonnier, selon des méthodes respectueuses de l'environnement, sans aucun pesticide. Nos escargots se nourrissent du support végétal naturel ensemencé chaque printemps, ainsi que de céréales biologiques adaptées.

				p.
					C'est la raison pour laquelle nos visites sont proposées durant la saison des escargots, de fin avril à fin septembre, lorsque les escargots sortent leur cornes .

				.quote.
					Des journées combinant repas à la ferme et visite sont également organisées. 06.27.09.56.82 et contact@lacaracole.fr

				p.
					Il s'agit de visites guidées à la fois gastronomiques et instructives pour les grands, et ludiques pour les enfants .
					Les repas sont servis sous une tonnelle, certains dimanches ou journées portes ouvertes, dans une ambiance familiale et conviviale.

				p.
					Nous proposons également un accueil des groupes ou associations. 
					N'hésitez pas à nous consulter pour connaitre nos prochaines dates d'ouverture (visite et/ou repas à la ferme), ou pour tout autre renseignement.  
				
				p
					| Vous pouvez également suivre nos prochains évènements sur 
					a(href="https://www.facebook.com/LaCaracole/") notre page Facebook.

			.photo
				img(src="../../assets/images/elevage.jpg" alt="")
					
		//- social-component(v-bind:enabledWidgets="['fbtimeline']")
		//- social-component(v-bind:enabledWidgets="['cdsscrollingravenarrow2']")
		<!--social-component(v-bind:enabledWidgets="['demo']")-->

</template>



<script>
	import BandeauInfoComponent from "../shared/BandeauInfoComponent";
	import TeaserIconComponent from "../shared/TeaserIconComponent";
	import GalerieComponent from "../shared/GalerieComponent";
	import ActuAndFeedbackComponent from "../shared/ActuAndFeedbackComponent";
	import SocialComponent from "../shared/SocialComponent";

	export default {
		name: "HomeContentComponent",
		props: ['someData'],
		components: {
			"bandeau-info-component": BandeauInfoComponent,
			"actu-and-feedback-component": ActuAndFeedbackComponent,
			"teaser-icon-component": TeaserIconComponent,
			"galerie-component": GalerieComponent,
			"social-component": SocialComponent
		}
	}
</script>

<style scoped>

</style>