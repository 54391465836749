<!-- Template -->
<template lang="pug">
	//- Icones
	//- =============================
	.blocs_icones

		.bloc
			img(src="../../assets/images/field.svg" alt="")
			span Elevés en conditions naturelles

		.bloc
			img(src="../../assets/images/leaf.svg" alt="")
			span Au rythme des saisons

		.bloc
			img(src="../../assets/images/sprout.svg" alt="")
			span En respect de l'environnement

		.bloc
			img(src="../../assets/images/agronomy.svg" alt="")
			span Avec un complément en céréales biologiques
</template>


<!-- Script -->
<script>
	export default {
		name: "TeaserIconComponent",
		props: ['someData']
	}
</script>

<style scoped>

</style>