<template lang="pug">


	
	//- Container principal
	//- =============================
	.main_container

		
		
		//- Header
		//- =============================
		header-component(some-data="I'm header")
		
		//- Navigation
		//- =============================
		content-router-component

		//- Contenu
		//- =============================
		.main_content
			keep-alive
				router-view


		//- Footer
		//- =============================
		footer-component(some-data="I'm footer")

</template>

<script>
	import HeaderComponent from "./HeaderComponent";
	import FooterComponent from "./FooterComponent";
	import ContentRouterComponent from "./ContentRouterComponent";

	export default {
		data: function () {
			return {
				status: "Parfait ! Tout est opérationnel en CI/CD."
			}},
		methods: {
			reverseMessage: function () {
				this.status = this.status.split('').reverse().join('')
			}
		},
		components: {
			"header-component": HeaderComponent,
			"content-router-component": ContentRouterComponent,
			"footer-component": FooterComponent
		}
	}
</script>

<style scoped>

</style>