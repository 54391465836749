<!-- Template -->
<template lang="pug">
	.section_social(v-if="enabledWidgets.length > 0")
		h2(v-if="enabledWidgets.indexOf('demo') > -1") Widgets Demo

		.social-widget-container(v-for="widget in tripAdvidsorWidgets" v-if="tripAdvidsorWidgets.length > 0 && (enabledWidgets.indexOf(widget.type) > -1 || enabledWidgets.indexOf('demo') > -1)")
			.social-widget(v-html="widget.html")
		br(v-if="tripAdvidsorWidgets.length > 0 && facebookWidgets.length > 0")
		.social-widget-container(v-for="widget in facebookWidgets" v-if="facebookWidgets.length > 0 && (enabledWidgets.indexOf(widget.type) > -1 || enabledWidgets.indexOf('demo') > -1)")
			.social-widget(v-html="widget.html")
</template>


<!-- Script -->
<script>
	export default {
		name: "SocialComponent",
		props: ['enabledWidgets'],
		data: function() {
			return {
				facebookWidgets: [
					{
						type: "fbtimeline",
						html:  `<div class="fb-page" data-href="https://www.facebook.com/LaCaracole" data-tabs="timeline" data-width="500" data-height="650" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"><blockquote cite="https://www.facebook.com/LaCaracole" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/LaCaracole">La Caracole en Cévennes - Producteur d&#039;escargots  Ferme Auberge</a></blockquote></div>`,
						src: "https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v5.0"
					},
				],
				tripAdvidsorWidgets: [
					{
						type: "selfserveprop851",
						html:  `<div id="TA_selfserveprop327" class="TA_selfserveprop"><ul id="E7BYKSSxkrpE" class="TA_links IneIyCc"><li id="VarwuvMdZv3a" class="XZlRUZE"><a target="_blank" href="https://www.tripadvisor.fr/"><img src="https://www.tripadvisor.fr/img/cdsi/img2/branding/150_logo-11900-2.png" alt="TripAdvisor"/></a></li></ul></div>`,
						src: "https://www.jscache.com/wejs?wtype=selfserveprop&uniq=327&locationId=4730019&lang=fr&rating=true&nreviews=5&writereviewlink=true&popIdx=true&iswide=false&border=true&display_version=2"
					},
					{
						type: "cdswritereviewlg",
						html:  `<div id="TA_cdswritereviewlg390" class="TA_cdswritereviewlg"><ul id="OMgKDG" class="TA_links 7KZce04A"><li id="Whrne6Y" class="nTU6bPfA4"><a target="_blank" href="https://www.tripadvisor.fr/"><img src="https://www.tripadvisor.fr/img/cdsi/img2/branding/medium-logo-12097-2.png" alt="TripAdvisor"/></a></li></ul></div>`,
						src: "https://www.jscache.com/wejs?wtype=cdswritereviewlg&uniq=390&locationId=4730019&lang=fr&lang=fr&display_version=2"
					},
					{
						type: "excellent",
						html:  `<div class="TA_excellent" id="TA_excellent767"><ul class="TA_links CG0XE2B" id="rYLZOhoK"><li class="XfWd2Vh4lNBb" id="I8U3waNRBo"><a target="_blank" href="https://www.tripadvisor.fr/"><img class="widEXCIMG" id="CDSWIDEXCLOGO" src="https://static.tacdn.com/img2/widget/tripadvisor_logo_115x18.gif" alt="TripAdvisor"/></a></li></ul></div>`,
						src: "https://www.jscache.com/wejs?wtype=excellent&uniq=767&locationId=4730019&lang=fr&display_version=2"
					},
					{
						type: "rated",
						html:  `<div class="TA_rated" id="TA_rated742"><ul class="TA_links t1bdsZn6RqgG" id="XEaJwe"><li class="cPVYLN" id="k7zaKmeMC"><a target="_blank" href="https://www.tripadvisor.fr/"><img src="https://www.tripadvisor.fr/img/cdsi/img2/badges/ollie-11424-2.gif" alt="TripAdvisor"/></a></li></ul></div>`,
						src: "https://www.jscache.com/wejs?wtype=rated&uniq=742&locationId=4730019&lang=fr&display_version=2"
					},
					{
						type: "cdsscrollingravenarrow",
						html:  `<div class="TA_cdsscrollingravenarrow" id="TA_cdsscrollingravenarrow449"><ul class="TA_links uQ1ahx9ko6dj" id="luFApO7LC"><li class="XTDoILL" id="2vL9HOj7jZrU"><a target="_blank" href="https://www.tripadvisor.fr/"><img class="widEXCIMG" id="CDSWIDEXCLOGO" src="https://static.tacdn.com/img2/t4b/Stacked_TA_logo.png" alt="TripAdvisor"/></a></li></ul></div>`,
						src: "https://www.jscache.com/wejs?wtype=cdsscrollingravenarrow&uniq=449&locationId=4730019&lang=fr&border=true&display_version=2"
					},
					{
						type: "socialButtonBubbles",
						html:  `<div id="TA_socialButtonBubbles901" class="TA_socialButtonBubbles"><ul id="O5tokl9" class="TA_links 5w28BHh"><li id="MMrpbbSs5H" class="54sndpdLcdGu"><a target="_blank" href="https://www.tripadvisor.fr/Attraction_Review-g4760517-d4730019-Reviews-La_Caracole-Saint_Florent_sur_Auzonnet_Gard_Occitanie.html"><img src="https://www.tripadvisor.fr/img/cdsi/img2/branding/socialWidget/20x28_green-21693-2.png"/></a></li></ul></div>`,
						src: "https://www.jscache.com/wejs?wtype=socialButtonBubbles&uniq=901&locationId=4730019&color=green&size=rect&lang=fr&display_version=2"
					},
					{
						type: "cdsscrollingravenarrow2",
						html:  `<div id="TA_cdsratingsonlynarrow23" class="TA_cdsratingsonlynarrow"><ul id="ZXiLdJIz" class="TA_links Tm09L0nSKTc"><li id="EErq67DmJwd" class="eDkkcmQv20Hz"><a target="_blank" href="https://www.tripadvisor.fr/"><img src="https://www.tripadvisor.fr/img/cdsi/img2/branding/tripadvisor_logo_transp_340x80-18034-2.png" alt="TripAdvisor"/></a></li></ul></div>`,
						src: "https://www.jscache.com/wejs?wtype=cdsratingsonlynarrow&uniq=23&locationId=4730019&lang=fr&border=true&display_version=2"
					},
				]
			}
		},
		mounted() {
			for (let widget of this.tripAdvidsorWidgets) {
				let widgetElem = document.createElement('script')
				widgetElem.setAttribute('src', widget.src)
				widgetElem.setAttribute('data-loadtrk', "")
				widgetElem.async = true
				// widgetElem.defer = true
				widgetElem.onload = function () { this.loadtrk=true }

				document.head.appendChild(widgetElem)
			}
			for (let widget of this.facebookWidgets) {
				let widgetElem = document.createElement('script')
				widgetElem.setAttribute('src', widget.src)
				widgetElem.setAttribute('data-loadtrk', "")
				widgetElem.setAttribute('crossorigin', "anonymous")
				widgetElem.async = true
				// widgetElem.defer = true
				widgetElem.onload = function () { this.loadtrk=true }

				document.head.appendChild(widgetElem)
			}
		},
	}
</script>

<style scoped>
	.social-widget-container {
		display: inline-block!important;
		vertical-align: middle;
	}
</style>