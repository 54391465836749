<template lang="pug">
	.content.page_contact
		h1 Contact
		div.contact
		//- Icones
		//- =============================
		.blocs_icones

			.bloc
				img(src="../../assets/images/phone.svg" alt="")
				span Numéro fixe: 04.66.25.65.70

			.bloc
				img(src="../../assets/images/mobile.svg" alt="")
				span Numéro portable: 06.27.09.56.82

			.bloc
				img(src="../../assets/images/mail.svg" alt="")
				span Email: contact@lacaracole.fr

			.bloc
				img(src="../../assets/images/facebook.svg" alt="")
				span
					| Retrouvez-nous sur 
					a(href="https://www.facebook.com/LaCaracole/") Facebook
		

		//- Map
		//- =============================
		iframe(src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11434.161060030356!2d4.1188829!3d44.2371227!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfc752a57927ddcc!2sLa%20Caracole!5e0!3m2!1sfr!2sfr!4v1602667102309!5m2!1sfr!2sfr" width="100%" height="500" frameborder="0" style="border:0" allowfullscreen="" aria-hidden="false" tabindex="0")
	</iframe>

</template>

<script>
    export default {
		name: "ContactContentComponent",
		props: ['someData'],
        mounted() {
            this.initMap();
        },
        methods: {
            initMap() {
		        // // The location of Uluru
		        // var center = {lat: 44.2371227, lng: 4.1188829};
		        // // The map, centered at Uluru
		        // var map = new google.maps.Map(
		        //     document.getElementById('map'), {zoom: 17, center: center});
		        // // The marker, positioned at Uluru
		        // var marker = new google.maps.Marker({position: center, map: map});
		    }
        }
	}



</script>

<style scoped>

</style>