<template lang="pug">
    header

        video(playsinline autoplay muted loop)
            source(src="../assets/images/clouds3.mp4" type="video/mp4")

        .logo
            img(src="../assets/images/la-caracole-logo.svg", alt="Logo La caracole")

        .baseline
            span La Caracole Producteur d'escargots en Cévennes 
            br
            span.tiret - 
            span Ferme auberge

        .grass_left
            img(src="../assets/images/grass-left.svg")

        .grass_right
            img(src="../assets/images/grass-right.svg")

        .snail1
            img(src="../assets/images/snail1.svg")

        .snail2
            img(src="../assets/images/snail2.svg")
</template>

<script>
    export default {
        name: "HeaderComponent",
        props: ['someData']
    }
</script>

<style scoped>

</style>