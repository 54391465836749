<template lang="pug">

  //- ####################################################
  //- Navigation
  //- ####################################################

  //nav
  //	router-link(to="/" active-class="active" exact) Accueil
  //	router-link(to="/ferme-auberge" active-class="active") Ferme auberge
  //	//- router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/2-accueil' } }" active-class="active" class="menu-parent" @mouseover.native="hover = true" @mouseleave.native="hover = false") Boutique
  //	//- 	span(v-show="hover" class="menu-child-container")
  //	//- 		router-link(:to="{name: 'boutique-gamme-gastronomique'}" active-class="active" class="menu-child") Produits gastronomiques
  //	//- 		router-link(:to="{name: 'boutique-carabel', params: { url: 'https://www.cara-bel.com/11-cara-bel-cosmetiques' } }" active-class="active" class="menu-child") Cosmétiques Carabel
  //	a(href="https://www.cara-bel.com/gastronomie" target="_blank" active-class="active") Boutique
  //	//- a(href="https://www.cara-bel.com/content/7-le-concept-cara-bel" target="_blank" active-class="active") Notre concept Carabel
  //	// router-link(to="/route-des-escargots" active-class="active") La route des escargots
  //	router-link(to="/contact" active-class="active") Contact
  //	router-link(:to="{name: 'boutique-panier', params: { url: 'https://www.cara-bel.com/panier?action=show' } }" active-class="active") Panier

  nav
    a(href="/" active-class="active" exact) Accueil
    a(href="/ferme-auberge" active-class="active") Ferme auberge
    a(href="https://www.cara-bel.com/gastronomie" target="_blank" active-class="active") Boutique
    a(href="/contact" active-class="active") Contact
    a(href="https://www.cara-bel.com/panier?action=show" active-class="active" target="_blank") Panier

</template>

<script>
	import HomeContentComponent from "./contents/HomeContentComponent";
	import ContactContentComponent from "./contents/ContactContentComponent";

	export default {
		name: "ContentRouterComponent",
		components: {
			"home-content-component": HomeContentComponent,
			"contact-content-component": ContactContentComponent,
		},
        mounted() {

        },
        data() {
            return {
                hover: false,
            };
        }
	}
</script>

<style scoped>

</style>