<!-- Template -->
<template lang="pug">
	.bandeau(:class="icon")
		img(src="../../assets/images/basket.svg" alt="")
		div(v-for="line in lines" v-html="line")
</template>


<!-- Script -->
<script>
	export default {
		name: "BandeauInfoComponent",
		props: {'icon': String,
				'lines': Array}
	}
</script>

<style scoped>

</style>