<!-- Template -->
<template lang="pug">
	.galerie
		.img_container
			img(src="../../assets/images/mr_soufflet2.jpg" alt="")
		.img_container
			img(src="../../assets/images/mme_soufflet.jpg" alt="")
		.img_container
			img(src="../../assets/images/gamme_la_caracole.jpg" alt="")
		.img_container
			img(src="../../assets/images/paella_escargots.jpg" alt="")

</template>


<!-- Script -->
<script>
	export default {
		name: "GalerieComponent",
		props: ['someData']
	}
</script>

<style scoped>

</style>