<template lang="pug">
    .content
        //- Colonne 1
        .col_1

            h1 La route des escargots

            .intro
                p.bold
                    | A découvrir au gré du vent, au gré du temps... suivez la route de nos escargots, en allant les déguster selon de multiples recettes dans des restaurants qui nous font confiance et choisissent notre production !
                p
                    | Galerie ici des plats proposés
                p.bold
                    | Prochainement, nous pourrons également vous proposer nos produits par correspondance.
                    | Contactez-nous dès à présent pour toute commande.
</template>

<script>
    export default {
        name: "RouteEscargotsContentComponent",
        props: ['someData']
    }
</script>

<style scoped>

</style>