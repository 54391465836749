<!-- Template -->
<template lang="pug">
	.section_actualites

		h2 Les news et avis des visiteurs

		.liste
			.bloc(v-for="post in actusFeedbacks" :class="post.type")
				.titre {{ post.titre }}
				p.detail.
					{{ post.message }}
				.date(v-if="post.date") {{ post.date }}
</template>


<!-- Script -->
<script>
	export default {
		name: "ActuAndFeedbackComponent",
		props: ['someData'],
        data: function() {
            return {
                actusFeedbacks: [
                    {
                        type: "avis",
                        titre: 'Barbara H.',
                        date: null,
                        message: "Nous y sommes allés le 5 mai et avons passés une très agréable journée. Avons appris beaucoup de choses sur l'élevage des escargots, avons goûter des specialités originales. Le repas fut un délice, moi qui suis pas très escargots, je me suis régalée et en ai même repris ! Je reviendrais en famille. Et l'accueil très sympathique."
                    },
                    {
                        type: "actu",
                        titre: 'Salon MIAM à Alès',
                        date: null,
                        message: "Bientôt notre RDV gastronomique au MIAM. Venez nous retrouver ... et vous régaler. Du 15 au 18 Novembre."
                    },
                    {
                        type: "avis",
                        titre: 'SoNnY Cw',
                        date: null,
                        message: "Super accueil , lieu chaleureux, j'ai adoré vos spécialités notamment les escargots confits à la graisse de canard et votre omelette aux escargots... un régal !! Je reviendrai avec la famille à bientôt!"
                    },
                    {
                        type: "avis",
                        titre: 'Karine B.',
                        date: null,
                        message: "On a très bien mangé. Un accueil super. Une visite après le repas sur l élevage des escargots. j ai adoré cette journée. 👍👍"
                    },
                    {
                        type: "avis",
                        titre: 'Jean Pierre M.',
                        date: null,
                        message: "J'ai decouvert l'auberge la caracole aujourd'hui. J'avais pu déguster déjà des produit en 1995 au Miam à Ales mais je n'etais jamais allé a saint florent. Très bon acceuil. Très bon produits. A découvrir pour ceux qui ne connaissent pas la valeur gustative des escargots. Cela fait plaisir de voir des gens se donner de la peine pour faire plaisir aux autres. Merci de votre acceuil et a très bientôt. ..."
                    },
                    {
                        type: "actu",
                        titre: 'Semaine cévenole au Fort Vauban',
                        date: null,
                        message: "RDV à tous nos amis et clients fidèles amateurs d'escargots, le week end du 5 et 6 octobre dans l'enceinte du Fort Vauban ! Au menu : escargots à la Cévenole , brochettes d'escargots en persillade à l'huile d'olive, et autres spécialités de la maison"
                    },
                ]
            }
        }
	}
</script>

<style scoped>

</style>