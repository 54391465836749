<template lang="pug">
	.content.page_carabel
		h1 Cara-Bel Présentation
		.argumentaire

			h2 Découvrez notre passion et notre savoir faire

			.texte
				p.bold.
					Profitez d'un déjeuner en terrasse couverte, dans un cadre typique et convivial

				p.
					Découvrez les recettes du chef : escargots à l’aïoli, escargots cuisinés dans leur coquille à la Cévenole servis à volonté, brochettes d'escargots en persillade à l'huile d'olive...

				p.
					Il y en a pour tous car nous proposons aussi quelques spécialités sans escargots, issues de producteurs fermiers des environs (ex : Mitonade de bœuf bio et ses légumes, à l'ancienne)...

				p.
					Enfin pour les groupes, accueil également en semaine : formule possible avec visite et repas ...

				.quote.
					Journées portes ouvertes et journées à thème : consultez-nous pour tout renseignement !



			.photo
				img(src="../../assets/images/plats.jpg" alt="")


</template>

<script>
    export default {
		name: "ContactContentComponent",
		props: ['someData'],
	}
</script>

<style scoped>

</style>