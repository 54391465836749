import Vue from "vue"
import VueRouter from 'vue-router'

import RootComponent from './components/RootComponent'
import HomeContentComponent from "./components/contents/HomeContentComponent";
import ContactContentComponent from "./components/contents/ContactContentComponent";
import FermeAubergeContentComponent from "./components/contents/FermeAubergeContentComponent";
import RouteEscargotsContentComponent from "./components/contents/RouteEscargotsContentComponent";
import ProductsContentComponent from "./components/contents/ProductsContentComponent";
import BoutiqueContentComponent from "./components/contents/BoutiqueContentComponent";
import CarabelContentComponent from "./components/contents/CarabelContentComponent";
import iFrameResize from 'iframe-resizer/js/iframeResizer'

declare let ga: any;

Vue.use(VueRouter)

Vue.directive('resize', {
    bind: function(el, { value = {} }) {
        el.addEventListener('load', () => iFrameResize(value, el))
    }
})

const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes: [
        { path: '/', name: 'home', component: HomeContentComponent },
        { path: '/ferme-auberge', name: 'ferme-auberge', component: FermeAubergeContentComponent },
        { path: '/boutique/gamme-gastronomique', name: 'boutique-gamme-gastronomique', component: ProductsContentComponent },
        { path: '/boutique', name: 'boutique', component: BoutiqueContentComponent, props: true },
        { path: '/boutique/carabel', name: 'boutique-carabel', component: BoutiqueContentComponent, props: true },
        { path: '/panier', name: 'boutique-panier', component: BoutiqueContentComponent, props: true },
        { path: '/carabel', name: 'cara-bel', component: CarabelContentComponent },
        { path: '/route-des-escargots', name: 'route-des-escargots', component: RouteEscargotsContentComponent },
        { path: '/contact', name: 'contact', component: ContactContentComponent },
    ]
})

router.afterEach((to, from) => {
    console.log("change");
    ga('send', 'pageview');
})

const app: Vue = new Vue({
    router,
    el: '#app',
    template: "<root-component/>",
    data: {
        title: "La caracole"
    },
    components: {
        "root-component": RootComponent
    }
})

