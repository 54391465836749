<template lang="pug">
	.content.page_gamme_produits



		//- Intro
		//- =============================

		.intro
			h1 Gamme de produits


			.photo
				img(src="../../assets/images/gamme_la_caracole.jpg" alt="")


			.texte
				.bold Nos produits sont élaborés artisanalement, sans aucun additif ni conservateur, dans notre atelier de transformation, en bocaux, en surgelé ou en frais, selon saison.

				.quote Plus d'une dizaine de recettes maison sur notre point de vente

				.quote Commandes en ligne, ou en vente directe à la ferme sur RDV, toute l'année, pour les particuliers.

				.quote Gamme spéciale, pour les restaurants et traiteurs.

				.quote Coffrets cadeaux


		//- Section Bloc d'icones
		//- =============================
		.section_icones
			teaser-icon-component



		//- Liste produits
		//- =============================
		.liste_produit

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/26-caracoltoast.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/caracoltoast.jpg" alt="caracoltoast">
				.texte Caracol'Toast

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/27-escargolive-.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/escargolive.jpg" alt="escargolive">
				.texte Escarg'Olive

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/24-photo-524-x-725.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/ESCARGOTS EN COQUILLES AU NATUREL.jpg" alt="ESCARGOTS EN COQUILLES AU NATUREL">
				.texte Escargots en coquilles au naturel

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/21-chairs-d-escargots-au-naturel.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/chairs-d-escargots-au-naturel.jpg" alt="chairs-d-escargots-au-naturel">
				.texte Chairs d'escargots au naturel

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/25--escargots-confits-a-la-graisse-de-canard-.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/escargots-confits-a-la-graisse-de-canard.jpg" alt="escargots-confits-a-la-graisse-de-canard">
				.texte Escargots confits à la graisse de canard

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/23-photo-524-x-725.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/CASSOLETTE A LA BOURGUIGNONNE.jpg" alt="CASSOLETTE A LA BOURGUIGNONNE">
				.texte Cassolette à la bourgignone


		//- Bandeau
		//- =============================	
		bandeau-info-component(icon="basket" v-bind:lines="['Nos produits sont directement proposés à la ferme, tant aux particuliers qu&apos;aux professionnels.', 'N&apos;hésitez pas à nous téléphoner pour prendre rendez-vous !']")



		//- Liste produits
		//- =============================
		.liste_produit
			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/35-cassolette-d-escargots-sauce-meridionale.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/cassolette-d-escargots-sauce-meridionale.jpg" alt="cassolette-d-escargots-sauce-meridionale">
				.texte Cassolette d'escargots sauce méridionale

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/34-coquilles-d-escargot-a-farcir.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/fricassee-cevenole-d-escargots-petit-gris-aux-cepes.jpg" alt="fricassee-cevenole-d-escargots-petit-gris-aux-cepes">
				.texte Fricassée cévenole d'escargots petit gris aux cèpes

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/44-terrine-d-escargots-de-la-caracole.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/terrine-d-escargots-de-la-caracole.jpg" alt="terrine-d-escargots-de-la-caracole">
				.texte Terrine d'escargots

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/38-marinade-d-escargots-petit-gris-a-l-huile-d-olive.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/marinade-d-escargots-petit-gris-a-l-huile-d-olive.jpg" alt="marinade-d-escargots-petit-gris-a-l-huile-d-olive">
				.texte Marinade d'escargots petit gris à l'huile d'olive

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/36-sauce-caracolade.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/sauce-caracolade.jpg" alt="sauce-caracolade">
				.texte Sauce caracolade

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/43-lot-aperitif-mix-de-4-bocaux-escarg-olive-caracol-toast.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/lot-aperitif-mix-de-4-bocaux-escarg-olive-caracol-toast.jpg" alt="lot-aperitif-mix-de-4-bocaux-escarg-olive-caracol-toast">
				.texte Lot mix d'apéritifs escargots

		//- Liste produits
		//- =============================
		.liste_produit

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/42-lot-mix-aperitif-escargots-et-olivades.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/lot-mix-aperitif-escargots-et-olivades.jpg" alt="lot-mix-aperitif-escargots-et-olivades">
				.texte Lot mix apéritif avec & sans escargots

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/33-croquilles-.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/croquilles.jpg" alt="croquilles">
				.texte Croquilles

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/34-coquilles-d-escargot-a-farcir.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/coquilles-d-escargot-a-farcir.jpg" alt="coquilles-d-escargot-a-farcir">
				.texte Coquilles d'escargot à farcir

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/37-escarg-olive.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/escarg-olive.jpg" alt="escarg-olive">
				.texte Olivade au gingembre

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/39-olivade-au-basilic.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/olivade-au-basilic.jpg" alt="olivade-au-basilic">
				.texte Olivade au basilic

			router-link(:to="{name: 'boutique', params: { url: 'https://www.cara-bel.com/gastronomie/40-olivade-provencale.html' } }" active-class="active").produit
				<img src="../../assets/images/produits/olivade-provencale.jpg" alt="olivade-provencale">
				.texte Olivade provençale




		//- Recettes
		//- =============================
		.recettes

			h2 Plus de 400 recettes
			p Il existe plus de 400 recettes autour de l’escargot , selon les terroirs de France… en omelette, en quiche, sur un lit de salade, à la Cévenole… à tartiner « à l’anglaise » sur toast chauds, ou encore à l’aïoli ,en brochettes, à l’huile d’Olive, pizza etc... .
			p Nous proposons une gamme d’escargots petits ou gros gris, cuisinés selon des recettes typiques.
			p Vous pourrez également trouver chez nous des escargots au naturel, ou crus, afin de les cuisiner selon vos recettes !

		
		.quote Vous nous trouverez également sur certaines manifestations de terroir, ou salons gastronomiques, alors... suivez nous à la trace !


</template>

<script>
	import BandeauInfoComponent from "../shared/BandeauInfoComponent";
	import TeaserIconComponent from "../shared/TeaserIconComponent";
	import GalerieComponent from "../shared/GalerieComponent";
	import ActuAndFeedbackComponent from "../shared/ActuAndFeedbackComponent";
	import SocialComponent from "../shared/SocialComponent";

	export default {
		name: "HomeContentComponent",
		props: ['someData'],
		components: {
			"bandeau-info-component": BandeauInfoComponent,
			"actu-and-feedback-component": ActuAndFeedbackComponent,
			"teaser-icon-component": TeaserIconComponent,
			"galerie-component": GalerieComponent,
			"social-component": SocialComponent
		}
	}
</script>

<style scoped>

</style>